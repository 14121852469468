
import AOS from 'aos'
import 'aos/dist/aos.css'
import { mapGetters } from "vuex";



export default {
	mounted() {
		AOS.init({
			once: true
		})
		const sliderIndexSwiper = new swiper.default('.slider-index .swiper', {
			modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
			loop: true,
			autoplayDisableOnInteraction: false,
			autoplay: true,
			slidesPerView: 1,
			// autoHeight: true,
			watchSlidesProgress: true,
			scrollbar: false,
			speed: 1800,
			autoplay: {
				delay: 7000,
			},
			on: {
				sliderMove: this.sliderMove,
				slideChangeTransitionStart: this.slideChangeTransitionStart
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: 'true',
				type: 'bullets',
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + '<span class="swiper-pagination-bar"></span>' + '<span class="swiper-pagination-progress"></span>' + '</span>';
				},
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		})

	},
	methods: {
		sliderMove() {
			document.querySelectorAll('.swiper-slide-next .slider-index__media').forEach((val) => {
				// val.style.display = 'none'
				// val.classList.remove("aos-init", "aos-animate");
				// AOS.init({
				// 	once: true
				// })
				// val.style.display = 'block'
			})
			document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
				val.style.display = 'none'
				val.classList.remove("aos-init", "aos-animate");
				val.style.display = 'block'
				val.classList.add("aos-init", "aos-animate");
			})
			// AOS.init()
		},
		slideChangeTransitionStart() {
			// document.querySelectorAll('.swiper-slide-active .slider-index__media').forEach((val) => {
			// 	val.style.display = 'none'
			// 	val.classList.remove("aos-init");
			// 	val.style.display = 'block'
			// })
			// document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
			// 	val.style.display = 'none'
			// 	val.classList.remove("aos-init");
			// 	val.style.display = 'block'
			// })
			// AOS.refreshHard()
		}
	},
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		}),
		slides() {
			return [
			 	// {
				// 	id: 2,
				// 	heading: "Скидка до 30%!",
				// 	content: `Зимняя резина в подарок. До 31.12`,
				// 	link: '/cars',
				// 	image: require('~/assets/img/slider-main/back-4.webp')
				// },

				{
					id: 1,
					heading: !this.$device.isMobile? `НА МОРЕ ВМЕСТЕ С AUTOEXPERT`:`НА МОРЕ ВМЕСТЕ <br> С AUTOEXPERT`,
					content: !this.$device.isMobile? `ПРИ ПОКУПКЕ АВТОМОБИЛЯ В КРЕДИТ`:`ПРИ ПОКУПКЕ <br> АВТОМОБИЛЯ В КРЕДИТ`,
          link: '/credit',
					image: require('~/assets/img/slider-main/back-4.webp')
				},
				{
					id: 2,
					heading: 'ЛЕТО НАЧИНАЕТСЯ',
					content: `С НОВОЙ МАШИНЫ, РАДОСТИ И ПОДАРКОВ`,
          link: '/credit',
					image: require('~/assets/img/slider-main/back-5.webp')
				},
				{
					id: 3,
					heading: 'IPHONE & АВТО',
					content: `ПРИ ПОКУПКЕ АВТОМОБИЛЯ В КРЕДИТ`,
          link: '/credit',
					image: require('~/assets/img/slider-main/back-6.webp')
				},
        {
          id: 4,
          heading: 'ДО МОСКВЫ',
          content: `ПОКРОЕМ РАСХОДЫ НА ПОЕЗДКУ`,
          link: '/credit',
          image: require('~/assets/img/slider-main/back-7.webp')
        },
        {
          id: 5,
          heading: !this.$device.isMobile? `АВТОКРЕДИТ ОТ ${this.settings.credit_percent}`:`АВТОКРЕДИТ<br>ОТ ${this.settings.credit_percent}`,
          content: `Со скидкой ${this.settings.sale_credit} ₽<br>Без первого взноса`,
          link: '/credit',
          image: require('~/assets/img/slider-main/back-1.webp')
        },
        {
          id: 6,
          heading: 'TRADE-IN',
          content: `Выгода ${this.settings.sale_tradein} ₽<br>Высокая стоимость оценки`,
          link: '/trade-in',
          image: require('~/assets/img/slider-main/back-2.webp')
        },
        {
          id: 7,
          heading: 'ВЫКУП',
          content: `По рыночной цене<br>Сделка в день обращения`,
          link: '/buyout',
          image: require('~/assets/img/slider-main/back-3.webp')
        },

			]
		}
	}
}
